body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*background-color: #babeca; #f3f6fb*/
  background-image: linear-gradient(to top, #babeca, #f3f6fb);
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;

}
.btn-primary {
  background-color: #3f5c17;
  border-color: #3f5c17
}

.btn-primary:hover, .btn-primary:active, .btn-primary:checked {
  background-color: #8ecb38;
  border-color: #8ecb38
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
